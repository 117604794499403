import React from 'react';

export const Menu = ()=> {
  return (<svg
      height="1.5em"
      width="1.5em"
      viewBox="0 0 73.168 73.168"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.242,14.425h64.684c2.344,0,4.242-1.933,4.242-4.324c0-2.385-1.898-4.325-4.242-4.325H4.242
				C1.898,5.776,0,7.716,0,10.101C0,12.493,1.898,14.425,4.242,14.425z M68.926,32.259H4.242C1.898,32.259,0,34.2,0,36.584
				c0,2.393,1.898,4.325,4.242,4.325h64.684c2.344,0,4.242-1.933,4.242-4.325C73.168,34.2,71.27,32.259,68.926,32.259z
				 M68.926,58.742H4.242C1.898,58.742,0,60.683,0,63.067c0,2.393,1.898,4.325,4.242,4.325h64.684c2.344,0,4.242-1.935,4.242-4.325
				C73.168,60.683,71.27,58.742,68.926,58.742z"/>
    </svg>
  )
}