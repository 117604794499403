import React from 'react';

export const Magazine = ()=>{
  return (<svg
    height="2em"
    width="2em"
    viewBox="0 -60 496 496"
    xmlns="http://www.w3.org/2000/svg"
  >

    <path d="m56 7.996094v304l192 32v-304zm32 184 128 32zm0-32 128 32zm0-32 128 32zm0 0"  className={'graphicColor2'}/>

    <path d="m248 39.996094v304l192-32v-304zm32 184 128-32zm0-32 128-32zm0-32 128-32zm0 0"  className={'graphicColor2'}/>

    <g className={'graphicColor1'} fill="#004fac">

      <path d="m488 47.996094h-40v-40c0-2.351563-1.035156-4.582032-2.832031-6.101563s-4.167969-2.171875-6.488281-1.785156l-190.679688 31.777344-190.679688-31.777344c-2.320312-.386719-4.691406.265625-6.488281 1.785156s-2.832031 3.75-2.832031 6.101563v40.050781h-40c-4.417969 0-8 3.582031-8 8v311.949219c0 4.417968 3.582031 8 8 8h480c4.417969 0 8-3.582032 8-8v-312c0-4.417969-3.582031-8-8-8zm-56 257.226562-176 29.328125v-287.777343l176-29.328126zm-368-287.777344 176 29.328126v287.777343l-176-29.328125zm416 342.550782h-464v-295.949219h32v247.949219c0 3.910156 2.824219 7.246094 6.679688 7.890625l192 32c.875.148437 1.765624.148437 2.640624 0l192-32c3.855469-.644531 6.679688-3.980469 6.679688-7.890625v-248h32zm0 0"/>

      <path d="m217.945312 184.238281-128-32c-4.273437-1.039062-8.582031 1.566407-9.648437 5.828125-1.070313 4.265625 1.503906 8.59375 5.757813 9.691406l128 32c.636718.160157 1.289062.242188 1.945312.238282 4.039062-.003906 7.4375-3.015625 7.929688-7.023438.492187-4.007812-2.078126-7.753906-5.992188-8.734375zm0 0"/>

      <path d="m217.945312 152.238281-128-32c-2.78125-.71875-5.734374.109375-7.734374 2.167969-2.003907 2.058594-2.75 5.03125-1.953126 7.792969.792969 2.757812 3.007813 4.878906 5.796876 5.558593l128 32c.636718.160157 1.289062.242188 1.945312.238282 4.039062-.003906 7.4375-3.015625 7.929688-7.023438.492187-4.007812-2.078126-7.753906-5.992188-8.734375zm0 0"/>

      <path d="m217.945312 216.238281-128-32c-4.273437-1.039062-8.582031 1.566407-9.648437 5.828125-1.070313 4.265625 1.503906 8.59375 5.757813 9.691406l128 32c.636718.160157 1.289062.242188 1.945312.238282 4.039062-.003906 7.4375-3.015625 7.929688-7.023438.492187-4.007812-2.078126-7.753906-5.992188-8.734375zm0 0"/>

      <path d="m280 199.996094c.65625.003906 1.3125-.078125 1.953125-.238282l128-32c4.253906-1.097656 6.828125-5.425781 5.757813-9.691406-1.066407-4.261718-5.375-6.867187-9.648438-5.828125l-128 32c-3.914062.980469-6.484375 4.726563-5.992188 8.734375.492188 4.007813 3.890626 7.019532 7.929688 7.023438zm0 0"/>

      <path d="m280 167.996094c.65625.003906 1.3125-.078125 1.953125-.238282l128-32c4.253906-1.097656 6.828125-5.425781 5.757813-9.691406-1.066407-4.261718-5.375-6.867187-9.648438-5.828125l-128 32c-3.914062.980469-6.484375 4.726563-5.992188 8.734375.492188 4.007813 3.890626 7.019532 7.929688 7.023438zm0 0"/>

      <path d="m280 231.996094c.65625.003906 1.3125-.078125 1.953125-.238282l128-32c4.253906-1.097656 6.828125-5.425781 5.757813-9.691406-1.066407-4.261718-5.375-6.867187-9.648438-5.828125l-128 32c-3.914062.980469-6.484375 4.726563-5.992188 8.734375.492188 4.007813 3.890626 7.019532 7.929688 7.023438zm0 0"/>
    </g>
  </svg>);
}