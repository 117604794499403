import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import {DateTime} from 'luxon';
import {Locked} from "../../Icons/locked";
import {Unlocked} from "../../Icons/unlocked";

export class CurrentDate extends Component{
  static propTypes = {
    currentLux: PropTypes.object,
    setUser: PropTypes.func,
    setEditing: PropTypes.func,
    editing: PropTypes.bool
  };

  render(){
    return(
      <div className={'currentDate'}>
        {this.props.currentLux.toLocaleString(DateTime.DATE_HUGE)}

        {this.props.setEditing ?
        <button className={'lockButton'}
          onClick={()=>this.props.setEditing(!this.props.editing)}>
          {this.props.editing ?
            <Unlocked/>
            :
            <Locked/>
          }
        </button>
          : ''}
      </div>
    )
  }
}