import jsPDF from 'jspdf';

export const createPDF = ({fileName, cover, days, pageOptions})=>{

  const pageWidth = pageOptions && pageOptions.pageWidth && typeof pageOptions.pageWidth === 'number' ? pageOptions.pageWidth : 8.5;

  const pageHeight = pageOptions && pageOptions.pageHeight && typeof pageOptions.pageHeight === 'number' ? pageOptions.pageHeight : 11;

  const fontSize = pageOptions && pageOptions.fontSize ? pageOptions.fontSize : 11;

  const margins = pageOptions && pageOptions.margins ? pageOptions.margins : {top: 1, left: 1, right: 1, bottom: 1};

  const contentWidth = (pageWidth - margins.left - margins.right);
  const pageCenter = (contentWidth / 2) + margins.left;

  const doc = new jsPDF({
    unit: 'in',
    format: 'letter'
  });

  let y = 0;
  if(cover){
    doc.setFontSize(30);

    //Make Title on title page
    y = 4;
    doc.text(pageCenter, y, cover.title, {align: 'center'});

    //Make subtitle on title page
    y += 2;
    doc.setFontSize(14);
    doc.text(pageCenter, y, cover.subtitle, {align: 'center'});
  }

  if(days){
    for(let i = 0; i < days.length; i++){
      //Create a new page if it's not the first page, or if there's a cover.
      if(i !== 0 || cover){
        doc.addPage();
      }

      const thisDay = days[i];
      y = margins.top + .5;

      //Add title to the page
      doc.setFontSize(18);
      doc.setFontStyle('bold');
      doc.text(pageWidth - margins.right, y, thisDay.date, {align: 'right'});
      y += .5;
      doc.setFont('Helvetica', '');
      doc.setFontSize(fontSize);

      //Write anything from this entry
      if(thisDay.entry) {
        const entryLines = doc.splitTextToSize(thisDay.entry, contentWidth);

        for(let e = 0; e < entryLines.length; e++){
          if(y > (pageHeight - margins.bottom)){
            doc.addPage();
            y = margins.top;
          }

          doc.text(margins.left, y, entryLines[e]);
          y += (fontSize + 2) / 72;
        }
        y += 2* ((fontSize + 2) / 72);
      }

      //If there was no entry but there is a comment, write 'no entry' in the entry space.
      if(!thisDay.entry && thisDay.commentary){
        y += 1* ((fontSize + 2) / 72);
        doc.text(pageCenter, y, "--- NO ENTRY ---", {align: 'center'});
        y += 3* ((fontSize + 2) / 72);
      }

      //If there's a comment, add a line, then put the commentary in italics
      if(thisDay.commentary){
        if(y > (pageHeight - margins.bottom)){
          doc.addPage();
          y = margins.top;
        }
        doc.setLineWidth(.01);
        doc.line(margins.left + .5, y, margins.left + contentWidth, y);
        y += 3* ((fontSize + 2) / 72);

        doc.setFontStyle('italic');
        const lines = doc.splitTextToSize(thisDay.commentary, contentWidth);

        for(let e = 0; e < lines.length; e++){
          if(y > (pageHeight - margins.bottom)){
            doc.addPage();
            y = margins.top;
          }

          doc.text(margins.left, y, lines[e]);
          y += (fontSize + 2) / 72;
        }
      }
      if(thisDay.photos && thisDay.photos.length){
        //Add photos to the page either one at a time, or multiple.
      }

    }
  }

  doc.save(`${fileName}.pdf`);
}