import React, {Component} from 'react';
import PropTypes from 'prop-types';
import request from "request";
import {_config} from "../../../config";
import {Garbage} from "../../Icons/garbage";

export class PhotoBar extends Component{
  static propTypes = {
    photos: PropTypes.arrayOf(PropTypes.object),
  };

  constructor(){
    super();

    this.state = {
      enlarge: null
    };

    this.setEnlarge = this.setEnlarge.bind(this);
    this.removePhoto = this.removePhoto.bind(this);
  }

  setEnlarge(index){
    this.setState({
      enlarge: index
    })
  }

  removePhoto(index){
    const photoToRemove = this.props.photos[index];

    this.props.user.getSession((err, session)=> {
      request
        .delete({
          url: `${_config.api.invokeUrl}/remove-photo`,
          json: true,
          body: {
            path: photoToRemove.path,
            date: +this.props.currentLuxon.toFormat('yyyyLLdd')
          },
          headers: {
            Authorization: session.getIdToken().getJwtToken()
          }
        }, (err, result, body)=>{
          if(err){
            console.log("ERROR", err);
          }
          if(body){
            console.log("Removed");
          }
        })
    });

    const photos = [...this.props.photos.slice(0, index), ...this.props.photos.slice(index + 1)];

    this.props.handleChange({target: {name: 'photos', value: photos}});
  }

  render(){
    const photos = this.props.photos.map((val, index)=>{
      if(val.type === 'loading'){
        return (
          <div
            key={index}
          >
            Loading...
          </div>
        )
      } else {
        return <SinglePhoto
          key={index}
          index={index}
          setEnlarge={this.setEnlarge}
          src={val.s3auth}
        />
      }
    })
    //Note: We stop propagation so that clicking in header doesn't focus in the textarea.
    return(
      <div>
        <hr style={{display: this.props.photos && this.props.photos.length ? '' : 'none'}}/>
        <div className={'photoBar'}>
          {photos}
        </div>

        {(this.state.enlarge !== null) ? (

          <div className={'modal'}>
            <div className={'backer'} onClick={()=>this.setEnlarge(null)}>
            </div>
            <div className={'body'}>
              <div className={'photo'}>
              <img className={'expandedImage'} src={this.props.photos[this.state.enlarge].s3auth} onClick={()=>this.setEnlarge(null)}/>
              </div>
              <div className={'text'}>
                <textarea style={{width: '100%'}}>

                </textarea>
              </div>
            </div>
          </div>
        ) : ''}
      </div>
    )
  }
}

const SinglePhoto = (props)=>{
  return (
    <div
      onClick={()=>{props.setEnlarge(props.index)}}
      className={'singlePhoto'}
    >
      <img
        src={props.src}
        height={'100%'}
      />
      <div
        className={'singleRowText'}
      >

      </div>
    </div>
  )
};