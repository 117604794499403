export const _config = {
  cognito: {
    userPoolId: 'us-east-2_X2fvIcG5M', // e.g. us-east-2_uXboG5pAb
    userPoolClientId: 'di99ieha3pcq41ft49tmtkqdm', // e.g. 25ddkmj4v6hfsfvruhpfi7n4hv
    region: 'us-east-2' // e.g. us-east-2
  },
  api: {
    invokeUrl: 'https://om2dl1yf38.execute-api.us-east-2.amazonaws.com/prod' // e.g. https://rc7nyt4tql.execute-api.us-west-2.amazonaws.com/prod',
  }

};