import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TextArea from "react-textarea-autosize";

export class TextAreaHOC extends Component{
  static propTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.string,
    handleChange: PropTypes.func,

  };

  constructor(){
    super();

    this.state = {
      enlarge: null,
      lastHeight: null
    };

    this.checkScroll = this.checkScroll.bind(this);
  }

  checkScroll(height){
    const thisTextarea = document.getElementById(this.props.id);
    const footer = document.getElementById('footer');
    const bottomOfTextarea = height + thisTextarea.offsetTop;

    const cutoff = window.scrollY + window.innerHeight - footer.clientHeight;

    if(bottomOfTextarea > cutoff && thisTextarea.selectionEnd === this.props.value.length && thisTextarea === document.activeElement){
      const newY = bottomOfTextarea - window.innerHeight + footer.clientHeight;
      window.scrollTo(0, newY);
    }
    this.setState({lastHeight: height})
  }

  render() {
  return(
    <TextArea
      id={this.props.id}
      className="currentTextArea"
      autoComplete={'off'}
      data-lpignore={true}
      disabled={this.props.disabled}
      name={this.props.name}
      value={this.props.value}
      onHeightChange={this.checkScroll}
      onChange={this.props.handleChange}
    />
  )
  }
}