import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import {changeTheme} from "../../Functions/changeTheme";

import {Link} from 'react-router-dom';
import {Magazine} from "../../Icons/magazine";
import {Menu} from "../../Icons/menu";

export class Header extends Component{
  static propTypes = {
    theme: PropTypes.bool,
    settings: PropTypes.bool,
    calendar: PropTypes.bool,
    today: PropTypes.bool,

    user: PropTypes.object,
    setUser: PropTypes.func
  };

  constructor(){
    super();

    this.state = {
      showMenu: false
    }

    this.logout = this.logout.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu(){
    this.setState(state=>({showMenu: !state.showMenu}))
  }

  logout(){
    this.props.user.signOut();
    this.props.setUser(null);
  }

  //Note: We stop propagation so that clicking in header doesn't focus in the textarea.
  render(){
    return(
      <header onClick={(event)=>event.stopPropagation()}>
        <div className="title">
          <Link className="accentColor" to="../">
            <Magazine/>
          </Link>
          <Link className="accentColor text" to="../">
            <Trans>PermaJournal</Trans>
          </Link>
        </div>

        <div className="navigation notMobile">
          <div className={`nav-item ${this.props.theme ? '' : 'hidden'}`}>
            <a className="accentColor" href="#" onClick={()=>changeTheme()}><Trans>Theme</Trans></a>
          </div>
          <div className={`nav-item ${this.props.user ? '' : 'hidden'}`}>
            <Link className="accentColor" to="/calendar"><Trans>Calendar</Trans></Link>
          </div>
          <div className={`nav-item ${this.props.user ? '' : 'hidden'}`}>
            <Link className="accentColor" to="/settings"><Trans>Settings</Trans></Link>
          </div>
          <div className={`nav-item ${this.props.user ? '' : 'hidden'}`}>
            <Link className="accentColor" to="/logout"><Trans>Log Out</Trans></Link>
          </div>
        </div>

        <div className="navigation mobile">
          <div className={`nav-item`}>
            <button className={'menuButton accentColor'} onClick={this.toggleMenu}>
              <Menu/>
            </button>
          </div>
        </div>

        <div className={`menuModal mobile backgroundColor ${this.state.showMenu ? '' : 'hidden'}` }>
          <div className={'header'}>
            <div className="title">
              <Link className="accentColor" to="../">
                <Magazine/>
              </Link>
              <Link className="accentColor text" to="../">
                <Trans>PermaJournal</Trans>
              </Link>
            </div>

            <div className="navigation mobile">
              <div className={`nav-item`}>
                <button className={'menuButton accentColor'} onClick={this.toggleMenu}>
                  <Menu/>
                </button>
              </div>
            </div>
          </div>

          <div className={'mobileNavigation'}>
            <div className={`nav-item ${this.props.theme ? '' : 'hidden'}`}>
              <a className="accentColor" href="#" onClick={()=>changeTheme()}><Trans>Theme</Trans></a>
            </div>
            <div className={`nav-item ${this.props.user ? '' : 'hidden'}`}>
              <hr/>
              <Link className="accentColor" to="/calendar"><Trans>Calendar</Trans></Link>
            </div>
            <div className={`nav-item ${this.props.user ? '' : 'hidden'}`}>
              <hr/>
              <Link className="accentColor" to="/settings"><Trans>Settings</Trans></Link>
            </div>
            <div className={`nav-item ${this.props.user ? '' : 'hidden'}`}>
              <hr/>
              <Link className="accentColor" to="/logout"><Trans>Log Out</Trans></Link>
            </div>
          </div>
        </div>


      </header>
    )
  }
}