import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Prompt} from 'react-router-dom';
import {Header} from "../../Components/Header";
import {MonthBar} from "../../Components/MonthBar";
import {Footer} from "../../Components/Footer";
import request from 'request';
import {DateTime} from "luxon";
import {CurrentDate} from "../../Components/CurrentDate";
import {_config} from "../../../config";
import {createPDF} from "../../Functions/createPDF";
import {PhotoBar} from "../../Components/PhotoBar";
import TextArea from 'react-textarea-autosize';
import {TextAreaHOC} from "../../Components/TextAreaHOC";

export class Past extends Component{
  static propTypes = {
    user: PropTypes.object,
    setUser: PropTypes.func,
    todayLuxon: PropTypes.object,
    currentLuxon: PropTypes.object,
    entryHistory: PropTypes.object,
  };

  constructor(){
    super();

    this.state = {
      entry: '',
      commentary: '',
      savedCommentary: '',
      photos: [],
      grammarlyInterval: null,
      editing: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.saveAll = this.saveAll.bind(this);
    this.saveCommentary = this.saveCommentary.bind(this);
    this.saveEntry = this.saveEntry.bind(this);
    this.checkForGrammarlyUpdate = this.checkForGrammarlyUpdate.bind(this);
    this.createPDF = this.createPDF.bind(this);
  }

  checkForGrammarlyUpdate(){
    const commentary = document.getElementById('commentaryTextarea');
    if(commentary && this.state.commentary !== commentary.value){
      this.setState({commentary: commentary.value});
    }
  }

  saveAll(){
    this.saveCommentary();
    this.saveEntry();
  }

  componentDidMount(){
    const grammarlyInterval = setInterval(this.checkForGrammarlyUpdate, 2000);

    const saved = this.props.entryHistory[this.props.currentLuxon.toFormat('yyyyLLdd')] ? this.props.entryHistory[this.props.currentLuxon.toFormat('yyyyLLdd')] : {e: '', c: '', p: []};

    this.setState({grammarlyInterval, entry: saved.e, commentary: saved.c, photos: saved.p});

    this.props.getSessionPromise({user: this.props.user})
      .then(({session})=>{
        request
          .get({
            url: `${_config.api.invokeUrl}/fetchpost?date=${this.props.match.params.date}`,
            headers: {
              Authorization: session.getIdToken().getJwtToken()
            }
          }, (error, response, body)=> {
            if(error){console.log(error)}
            if(body){
              const parsedBody = JSON.parse(body);
              if(parsedBody.date === +this.props.match.params.date){
                this.setState({
                  entry: parsedBody.e,
                  commentary: parsedBody.c,
                  photos: parsedBody.p,
                  lastSave: Date.now()
                });
              }
            }
          })
      });
  }

  componentDidUpdate(prevProps){
    if(!prevProps.ready && this.props.ready){
      const thisDate = this.props.currentLuxon.toFormat('yyyyLLdd');
      const saved = this.props.entryHistory[thisDate] ? this.props.entryHistory[thisDate] : {e: '', c: '', p: []};

      this.setState({entry: saved.e, commentary: saved.c, photos: saved.p, editing: false});
    }
    if(prevProps.match.params.date !== this.props.match.params.date){
      const thisDate = this.props.currentLuxon.toFormat('yyyyLLdd');
      const saved = this.props.entryHistory[thisDate] ? this.props.entryHistory[thisDate] : {e: '', c: '', p: []};

      this.setState({entry: saved.e, commentary: saved.c, photos: saved.p, editing: false});

      this.props.getSessionPromise({user: this.props.user})
        .then(({session})=>{
          request
            .get({
              url: `${_config.api.invokeUrl}/fetchpost?date=${this.props.match.params.date}`,
              headers: {
                Authorization: session.getIdToken().getJwtToken()
              }
            }, (error, response, body)=> {
              if(error){console.log(error)}
              if(body){
                const parsedBody = JSON.parse(body);
                if(parsedBody.date === +this.props.match.params.date){
                  this.setState({
                    entry: parsedBody.e,
                    commentary: parsedBody.c,
                    photos: parsedBody.p,
                    lastSave: Date.now()
                  });
                }
              }
            })
        });
    }
  }

  componentWillUnmount(){
    if(this.state.grammarlyInterval){
      clearInterval(this.state.grammarlyInterval);
      clearTimeout(this.state.timer);
    }
  }

  createPDF(){
    const thisLuxon = DateTime.fromISO(this.props.match.params.date);
    createPDF({
      fileName: thisLuxon.toFormat('yyyy-LL-dd'),
      cover: {
        title: 'PermaJournal',
        subtitle: thisLuxon.toLocaleString(DateTime.DATE_FULL)
      },
      days: [
        {
          date: thisLuxon.toLocaleString(DateTime.DATE_HUGE),
          entry: this.state.entry,
          commentary: this.state.commentary
        }
      ]
    })
  }

  saveCommentary(){
    const thisDate = this.props.currentLuxon.toFormat('yyyyLLdd');
    const commentary = document.getElementById('commentaryTextarea') ? document.getElementById('commentaryTextarea').value : '';
    const savedCommentary = this.props.entryHistory[thisDate] ? this.props.entryHistory[thisDate].c : '';

    this.props.user.getSession((err, session)=> {
      if (session && session.isValid() && savedCommentary !== commentary) {
        const formData = {
          date: +this.props.match.params.date,
          commentary: commentary
        };

        request
          .post({
            url: `${_config.api.invokeUrl}/update-commentary`,
            json: true,
            body: formData,
            headers: {
              Authorization: session.getIdToken().getJwtToken()
            }
          })
          .on('response', (response) => {
            this.setState({
              lastSave: Date.now()
            });
            this.props.updateEntryHistory({date: this.props.match.params.date, commentary: commentary});
            console.log("SUCCESS", response)
          })
          .on('error', (error) => {
            console.log("ERROR", error)
          })
      }
    });
  }

  handleChange(event){
    if(event.target.name === 'editing'){
      this.setState({editing: event.target.value});
    }

    if(event.target.name === 'photos'){
      this.setState({[event.target.name]: event.target.value});
    }

    if(event.target.name === 'commentary'){
      console.log("COMMENTARY CHANGED")
      clearTimeout(this.state.timer);
      this.setState({timer: setTimeout(()=>{this.saveCommentary()}, 5000)})
      this.setState({[event.target.name]: event.target.value});
    }

    if(event.target.name === 'entry'){
      console.log("Entry CHANGED")
      clearTimeout(this.state.entryTimer);
      this.setState({entryTimer: setTimeout(()=>{this.saveEntry()}, 5000)})
      this.setState({[event.target.name]: event.target.value});
    }
  }

  saveEntry(){
    const entry = document.getElementById('entryTextarea').value;
    const savedEntry = this.props.entryHistory[this.props.todayLuxon.toFormat('yyyyLLdd')] ? this.props.entryHistory[this.props.todayLuxon.toFormat('yyyyLLdd')].e : null;

    this.props.getSessionPromise({user: this.props.user})
      .then(({session})=>{
        if(entry && entry !== savedEntry){
          const formData = {
            date: +this.props.match.params.date,
            entry: entry
          };

          request
            .post({
              url: `${_config.api.invokeUrl}/update-entry`,
              json: true,
              body: formData,
              headers: {
                Authorization: session.getIdToken().getJwtToken()
              }
            })
            .on('response', (response) => {
              this.setState({
                lastSave: Date.now()
              });
              this.props.updateEntryHistory({date: this.props.match.params.date, entry: entry});
              console.log("SUCCESS", response)
            })
            .on('error', (error) => {
              console.log("ERROR", error)
            })
        }
      })
      .catch(err=>{console.log(err)});
  }

  focusCursor(){
    // document.getElementById('commentaryTextarea').focus()
  }

  render(){
    const thisDate = this.props.currentLuxon.toFormat('yyyyLLdd');
    const savedCommentary = this.props.entryHistory[thisDate] ? this.props.entryHistory[thisDate].c : '';
    const savedEntry = this.props.entryHistory[thisDate] ? this.props.entryHistory[thisDate].e : '';

    return(
      <div onClick={this.focusCursor}>

        <Prompt
          when={
            (!!this.state.commentary
              || !!savedCommentary
              || !!this.state.entry
              || !!savedEntry)
            && this.state.commentary !== savedCommentary
            && this.state.entry !== savedEntry}
          message={()=>'You have unsaved changes! Are you sure you want to leave?'}
        />

        <div className={'backer'}/>
        <div className={'container'}>
          <Header
            theme={true}
            user={this.props.user}
            setUser={this.props.setUser}
          />
          <MonthBar
            showActive={true}
            date={this.props.match.params.date}
            todayLuxon={this.props.todayLuxon}
            entryHistory={this.props.entryHistory}
            ready={this.props.ready}
          />
          <CurrentDate
            currentLux={DateTime.fromISO(this.props.match.params.date)}
            editing={this.state.editing}
            setEditing={(bool)=>{
              this.setState({editing: bool}, ()=>{
                if(bool){
                  document.getElementById('entryTextarea').focus()
                } else {
                  document.getElementById('commentaryTextarea').focus()
                }
              });
            }}
          />

          <div id={'entry'}>
            <TextAreaHOC
              id={'entryTextarea'}
              name={'entry'}
              disabled={!this.state.editing || !this.props.ready}
              value={this.state.entry}
              handleChange={this.handleChange}
            />
            <hr/>

            <TextAreaHOC
              id={'commentaryTextarea'}
              className="currentTextArea commentary"
              name={'commentary'}
              value={this.state.commentary}
              disabled={!this.props.ready}
              handleChange={this.handleChange}
            />
            <PhotoBar
              user={this.props.user}
              handleChange={this.handleChange}
              photos={this.state.photos}
              currentLuxon={DateTime.fromISO(this.props.match.params.date)}
            />
          </div>
          <div style={{height: '2em'}}/>
        </div>
        <Footer
          ready={this.props.ready}
          user={this.props.user}
          isSaved={this.state.commentary === savedCommentary && this.state.entry === savedEntry}
          lastSave={this.state.lastSave}
          photos={this.state.photos}
          currentLuxon={DateTime.fromISO(this.props.match.params.date)}
          handleChange={this.handleChange}
          saveChanges={this.saveAll}
          createPDF={this.createPDF}
        />
      </div>
    )
  }
}
