import React from 'react';
import PropTypes from 'prop-types';
import {DateTime} from 'luxon';

export const TimeSelect = (props)=>{
  const startOfDay = DateTime.local().startOf('day')
  
  return (
    <select
      id={props.name}
      className="inputBorder inputGroup"
      name={props.name}
      value={props.value}
      onChange={props.handleChange}
    >
      <option value="0">{startOfDay.plus({hours: 0}).toLocaleString(DateTime.TIME_SIMPLE)}</option>
      <option value="1">{startOfDay.plus({hours: 1}).toLocaleString(DateTime.TIME_SIMPLE)}</option>
      <option value="2">{startOfDay.plus({hours: 2}).toLocaleString(DateTime.TIME_SIMPLE)}</option>
      <option value="3">{startOfDay.plus({hours: 3}).toLocaleString(DateTime.TIME_SIMPLE)}</option>
      <option value="4">{startOfDay.plus({hours: 4}).toLocaleString(DateTime.TIME_SIMPLE)}</option>
      <option value="5">{startOfDay.plus({hours: 5}).toLocaleString(DateTime.TIME_SIMPLE)}</option>
      <option value="6">{startOfDay.plus({hours: 6}).toLocaleString(DateTime.TIME_SIMPLE)}</option>
      <option value="7">{startOfDay.plus({hours: 7}).toLocaleString(DateTime.TIME_SIMPLE)}</option>
      <option value="9">{startOfDay.plus({hours: 9}).toLocaleString(DateTime.TIME_SIMPLE)}</option>
      <option value="10">{startOfDay.plus({hours: 10}).toLocaleString(DateTime.TIME_SIMPLE)}</option>
      <option value="11">{startOfDay.plus({hours: 11}).toLocaleString(DateTime.TIME_SIMPLE)}</option>
      <option value="12">{startOfDay.plus({hours: 12}).toLocaleString(DateTime.TIME_SIMPLE)}</option>
      <option value="13">{startOfDay.plus({hours: 13}).toLocaleString(DateTime.TIME_SIMPLE)}</option>
      <option value="14">{startOfDay.plus({hours: 14}).toLocaleString(DateTime.TIME_SIMPLE)}</option>
      <option value="15">{startOfDay.plus({hours: 15}).toLocaleString(DateTime.TIME_SIMPLE)}</option>
      <option value="16">{startOfDay.plus({hours: 16}).toLocaleString(DateTime.TIME_SIMPLE)}</option>
      <option value="17">{startOfDay.plus({hours: 17}).toLocaleString(DateTime.TIME_SIMPLE)}</option>
      <option value="18">{startOfDay.plus({hours: 18}).toLocaleString(DateTime.TIME_SIMPLE)}</option>
      <option value="19">{startOfDay.plus({hours: 19}).toLocaleString(DateTime.TIME_SIMPLE)}</option>
      <option value="20">{startOfDay.plus({hours: 20}).toLocaleString(DateTime.TIME_SIMPLE)}</option>
      <option value="21">{startOfDay.plus({hours: 21}).toLocaleString(DateTime.TIME_SIMPLE)}</option>
      <option value="22">{startOfDay.plus({hours: 22}).toLocaleString(DateTime.TIME_SIMPLE)}</option>
      <option value="23">{startOfDay.plus({hours: 23}).toLocaleString(DateTime.TIME_SIMPLE)}</option>
    </select>
  )
};

TimeSelect.propTypes = {
  name: PropTypes.string,
  timezone: PropTypes.string,
  handleChange: PropTypes.func
};