import React, {Component} from 'react';
import {Header} from "../../Components/Header";
import PropTypes from "prop-types";
import {TimeZoneSelect} from "../../Components/TimeZoneSelect";
import {TimeSelect} from "../../Components/TimeSelect";

export class Settings extends Component{
  static propTypes = {
    settings: PropTypes.object,
    updateSettings: PropTypes.func,
    user: PropTypes.object,
    setUser: PropTypes.func,
  };

  render(){
    return(
      <div className={'container'}>
        <Header
          theme={true}
          user={this.props.user}
          setUser={this.props.setUser}
        />

        <h1>Settings</h1>
        <div className="settingsGroup">
          <h3>Current Plan</h3>
          <div className="settingsGroupOptions">

          </div>
        </div>


        <div className="settingsGroup">
          <h3>User Details</h3>

          <div className="settingsGroupOptions">
            <div className="inputGroup">
              <input
                className="inputBorder inputGroup"
                id="name"
                type="text"
                placeholder="Name"
                name={'name'}
                value={this.props.settings.name}
                onChange={this.props.updateSettings}
              />
              <label htmlFor="name">Name</label>
            </div>
            {/*<div className="inputGroup">*/}
              {/*<input*/}
                {/*className="inputBorder"*/}
                {/*id="email"*/}
                {/*type="email"*/}
                {/*placeholder="Email"*/}
                {/*name={'email'}*/}
                {/*value={this.props.settings.email}*/}
                {/*onChange={this.props.updateSettings}*/}
              {/*/>*/}
              {/*<label htmlFor="email">Email</label>*/}
            {/*</div>*/}
            <div className="inputGroup">
              <TimeZoneSelect
                value={this.props.settings.timezone}
                handleChange={this.props.updateSettings}
              />
              <label htmlFor="timezone">Time Zone</label>
            </div>
            <div className="inputGroup">
              <TimeSelect
                name={'endOfDay'}
                value={this.props.settings.endOfDay}
                handleChange={this.props.updateSettings}
              />
              <label htmlFor="endOfDay">End Of Day</label>
            </div>

          </div>
        </div>
        <div>
          {JSON.stringify(this.props.settings) !== JSON.stringify(this.props._settings) ? <button onClick={this.props.saveSettings}>Save</button> : ''}
        </div>

        {/*<div className="settingsGroup">*/}
          {/*<h3>Password</h3>*/}

          {/*<div className="settingsGroupOptions">*/}
            {/*<div className="inputGroup">*/}
              {/*<input className="inputBorder" id="currentPassword" type="password" placeholder="Current Password"/>*/}
              {/*<label htmlFor="currentPassword">Current Password</label>*/}
            {/*</div>*/}
            {/*<div className="inputGroup">*/}
              {/*<input className="inputBorder" id="newPassword" type="password" placeholder="New Password"/>*/}
              {/*<label htmlFor="newPassword">New Password</label>*/}
            {/*</div>*/}
          {/*</div>*/}
        {/*</div>*/}


        {/*<div className="settingsGroup">*/}
        {/*<h3>Shipping and Billing</h3>*/}
        {/*<div className="settingsGroupOptions">*/}
        {/*<div className="inputGroup">*/}
        {/*<input className="inputBorder" id="mailingAddress" type="text" placeholder="Mailing Address"/>*/}
        {/*<label htmlFor="mailingAddress">Mailing Address</label>*/}
        {/*</div>*/}
        {/*<div className="inputGroup">*/}
        {/*<input className="inputBorder" id="mailingAddress2" type="text" placeholder="Mailing Address"/>*/}
        {/*<label htmlFor="mailingAddress2"></label>*/}
        {/*</div>*/}
        {/*<div className="inputGroup">*/}
        {/*<input className="inputBorder" id="mailingCity" type="text" placeholder="Mailing Address"/>*/}
        {/*<label htmlFor="mailingCity">City</label>*/}
        {/*</div>*/}
        {/*<div className="inputGroup">*/}
        {/*<input className="inputBorder" id="mailingState" type="text" placeholder="Mailing Address"/>*/}
        {/*<label htmlFor="mailingState">State</label>*/}
        {/*</div>*/}
        {/*<div className="inputGroup">*/}
        {/*<input className="inputBorder" id="mailingZip" type="text" placeholder="Mailing Address"/>*/}
        {/*<label htmlFor="mailingZip">Zip</label>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*<div className="settingsGroupOptions">*/}
        {/*<div className="inputGroup">*/}
        {/*<input className="inputBorder" id="billingAddress" type="text" placeholder="Billing Address"/>*/}
        {/*<label htmlFor="billingAddress">Billing Address</label>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}
      </div>
    )
  }
}