import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {DateTime} from 'luxon';

import {Link} from 'react-router-dom';

export class MonthBar extends Component{
  static propTypes = {
    showActive: PropTypes.bool,
    date: PropTypes.string,
    todayLuxon: PropTypes.object,
    entryHistory: PropTypes.object,
    ready: PropTypes.bool
  };

  constructor(props){
    super(props);

    this.state = {
      ready: props.ready
    }
  }

  render(){
    const today = this.props.todayLuxon;
    const selectedDate = this.props.showActive ? DateTime.fromISO(this.props.date) : null;
    const month = DateTime.fromISO(this.props.date).startOf('month');
    const dayButtons = [];



    if(this.props.segment === 'week'){

    } else {
      for (let i = 0; i < month.daysInMonth; i++) {
        dayButtons.push(
          <SingleDay
            key={i}
            today={today}
            entryHistory={this.props.entryHistory}
            selectedDate={selectedDate}
            currentDate={month.plus({days: i})}
          />)
      }
    }
    //Note: We stop propagation so that clicking in header doesn't focus in the textarea.
    return(
      <div style={{position: 'relative', width: '100%'}}>
        <div className={`dailyBarLoader ${this.props.ready ? 'fullyLoaded' : 'unloaded'} ${this.state.ready ? 'invisible' : ''}`}/>
        <div className={`dailyBar ${this.props.ready ? '' : 'invisible'}`} onClick={(event)=>event.stopPropagation()}>
          {dayButtons}
        </div>
      </div>
    )
  }
}

const SingleDay = (props)=>{
  let to = `/date/${props.currentDate.toFormat('yyyyLLdd')}`;
  let title= props.currentDate.toLocaleString(DateTime.DATE_HUGE);
  const classNames = ['singleDay'];
  if(props.selectedDate
    && props.currentDate.hasSame(props.selectedDate, 'year')
    && props.currentDate.hasSame(props.selectedDate, 'month')
    && props.currentDate.hasSame(props.selectedDate, 'day')
  ){
    classNames.push('active');
  }

  if(props.currentDate.hasSame(props.today, 'year') && props.currentDate.hasSame(props.today, 'month') && props.currentDate.hasSame(props.today, 'day')){
    classNames.push('today');
    to = '/'
  } else if(props.currentDate > props.today){
    classNames.push('future');
  } else  if(props.currentDate < props.today){
    classNames.push('past');
  }

  const dateInHistory = props.entryHistory ? props.entryHistory[props.currentDate.toFormat('yyyyLLdd')] : false;
  if(dateInHistory){
    if(dateInHistory.e){
      classNames.push('hasEntry');
      title += ', Has Entry';
    }
    if(dateInHistory.c){
      classNames.push('hasComment');
      title += ', Has Comment';

    }
  }

  return (
    <Link
      to={to}
      title={title}
      className={classNames.join(' ')}
    />
  )
};

const MultiDay = (props)=>{
  let to = `/date/${props.currentDate.toFormat('yyyyLLdd')}`;
  let title= props.currentDate.toLocaleString(DateTime.DATE_HUGE);
  const classNames = ['multiDay'];
  if(props.selectedDate
    && props.currentDate.hasSame(props.selectedDate, 'year')
    && props.currentDate.hasSame(props.selectedDate, 'month')
    && props.currentDate.hasSame(props.selectedDate, 'day')
  ){
    classNames.push('active');
  }

  if(props.currentDate.hasSame(props.today, 'year') && props.currentDate.hasSame(props.today, 'month') && props.currentDate.hasSame(props.today, 'day')){
    classNames.push('today');
    to = '/'
  } else if(props.currentDate > props.today){
    classNames.push('future');
  } else  if(props.currentDate < props.today){
    classNames.push('past');
  }

  const dateInHistory = props.entryHistory ? props.entryHistory[props.currentDate.toFormat('yyyyLLdd')] : false;
  if(dateInHistory){
    if(dateInHistory.e){
      classNames.push('hasEntry');
      title += ', Has Entry';
    }
    if(dateInHistory.c){
      classNames.push('hasComment');
      title += ', Has Comment';

    }
  }

  return (
    <Link
      to={to}
      title={title}
      className={classNames.join(' ')}

    />
  )
}
