import React, {Component} from 'react';
import {Header} from "../../Components/Header";
import PropTypes from "prop-types";
import {DateTime} from 'luxon';
import {MonthBar} from "../../Components/MonthBar";

export class Calendar extends Component{
  static propTypes = {
    entryHistory: PropTypes.object,
    user: PropTypes.object,
    todayLuxon: PropTypes.object,
    setUser: PropTypes.func
  };

  render(){


    const startDate = Object
      .keys(this.props.entryHistory || {})
      .map(val=>+val)
      .sort((a,b)=>b-a)
      .pop()
    || this.props.todayLuxon.toFormat('yyyyLLdd');

    console.log(startDate);
    const startLux = DateTime.fromISO(startDate);
    let currentLux = DateTime.local();

    const years = [];

    while(currentLux >= startLux){
      const start = currentLux.startOf('year') > startLux ? currentLux.startOf('year') : startLux;

      console.log(currentLux.toLocaleString(DateTime.DATE_HUGE));
      console.log(start.toLocaleString(DateTime.DATE_HUGE));
      years.push(
        <CalendarYear
          key={currentLux.toFormat('yyyy')}
          endLux={currentLux}
          startLux={start}
          todayLuxon={this.props.todayLuxon}
          entryHistory={this.props.entryHistory}
          ready={this.props.ready}
        />);
      currentLux = currentLux.plus({years: -1}).endOf('year');
    }

    return(
      <div className={'container'}>
        <Header
          theme={true}
          user={this.props.user}
          setUser={this.props.setUser}
        />
        {years}
      </div>
    )
  }
}

const CalendarYear = (props)=>{
  const months = [];

  let currentLux = props.endLux;

  while(currentLux >= props.startLux){
    months.push(
      <div key={currentLux.toFormat('LLLL')}>
        <div className={'calendarMonthTitle'}>{currentLux.toFormat('LLLL')}</div>
        <MonthBar
          showActive={false}
          date={currentLux.toFormat('yyyyLLdd')}
          entryHistory={props.entryHistory}
          todayLuxon={props.todayLuxon}
          ready={props.ready}
        />
      </div>
    );
    currentLux = currentLux.minus({month: 1});
  }


  return(
    <div className={'calendarYear'}>
      <div className={'calendarYearDiv'}>{props.startLux.toFormat('yyyy')}</div>
      <div className={'calendarMonthsDiv'}>{months}</div>
    </div>
  )
}
