import React, {Component} from 'react';
import {Router, Switch, Route, Redirect} from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import { I18nProvider } from '@lingui/react'
import {_config} from "../config";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import {Login} from "../ui/Pages/login";
import {Today} from "../ui/Pages/today/component";
import {Past} from "../ui/Pages/date";
import {Calendar} from "../ui/Pages/calendar";
import {Settings} from "../ui/Pages/settings";
import {Register} from "../ui/Pages/register";
import {setTheme} from "../ui/Functions/changeTheme";
import {DateTime} from 'luxon';
import request from "request";

const browserHistory = createBrowserHistory();

export class MainRouter extends Component {

  constructor(){
    super();

    this.state = {
      user: null,
      userPool: null,
      todayLuxon: DateTime.local(),
      entryHistory: {},
      ready: false,
      _settings: null,
      settings: {
        name: '',
        email: '',
        timezone: '',
        endOfDay: 0,
        reminders: []
      }
    };

    this.initialSetup = this.initialSetup.bind(this);
    this.setUser = this.setUser.bind(this);
    this.logout = this.logout.bind(this);
    this.updateSettings = this.updateSettings.bind(this);
    this.saveSettings = this.saveSettings.bind(this);
    this.fetchSettings = this.fetchSettings.bind(this);
    this.updateEntryHistory = this.updateEntryHistory.bind(this);
    this.getSessionPromise = this.getSessionPromise.bind(this);
    this.fetchHistory = this.fetchHistory.bind(this);
  }

  componentDidMount(){
    setTheme();
    const poolData = {
      UserPoolId: _config.cognito.userPoolId,
      ClientId: _config.cognito.userPoolClientId
    };

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const user = userPool.getCurrentUser();

    this.setState({userPool, user});
    this.initialSetup({user});
  }

  setUser(user){
    this.setState({user: user});
    this.initialSetup({user});
  }

  updateSettings(event){
    const settings = this.state.settings;
    settings[event.target.name] = event.target.value;
    this.setState({settings});
  }

  saveSettings(){
    this.getSessionPromise({user: this.state.user})
      .then(({session})=>{
        const formData = JSON.parse(JSON.stringify(this.state.settings));
        request
          .post({
            url: `${_config.api.invokeUrl}/update-settings`,
            json: true,
            body: formData,
            headers: {
              Authorization: session.getIdToken().getJwtToken()
            }
          })
          .on('response', (response) => {
            this.setState({
              settings: formData,
              _settings: formData
            });
            console.log("SUCCESS", response)
          })
          .on('error', (error) => {
            console.log("ERROR", error)
          })
      });
  }

  updateEntryHistory({date, entry, commentary, photos}){
    let update = this.state.entryHistory[date] ? this.state.entryHistory[date] : {e: '', c: '', p: []};

    if(entry !== undefined){
      update.e = entry;
    }
    if(commentary !== undefined){
      update.c = commentary;
    }
    if(photos !== undefined){
      update.p = photos;
    }

    const newEntryHistory = ({...this.state.entryHistory, [date]: update});

    this.setState({entryHistory: newEntryHistory});
  }

  initialSetup({user}) {
    this.getSessionPromise({user})
      .then(({session}) => Promise.all([
        this.fetchSettings({session}),
        this.fetchHistory({session})
      ]))
      .then((result) => {
        this.setState({ready: true});
        console.log(result);
      })
      .catch(err => {
        console.log(err);
      })
  }

  fetchSettings({session}){
    return new Promise((resolve, reject)=>{
      request
        .get({
          url: `${_config.api.invokeUrl}/fetch-settings`,
          headers: {
            Authorization: session.getIdToken().getJwtToken()
          }
        }, (error, response, body)=> {
          if(error){
            reject(error);
          }
          if(body){
            const parsedBody = JSON.parse(body);

            const todayLuxon = DateTime.local().setZone(parsedBody.settings.timezone).minus({hours: parsedBody.settings.endOfDay});

            this.setState({
              todayLuxon,
              settings: JSON.parse(JSON.stringify(parsedBody.settings)),
              _settings: JSON.parse(JSON.stringify(parsedBody.settings)),
            }, ()=>{
              resolve({settings: JSON.parse(JSON.stringify(parsedBody.settings))});
            });
          }
        })
    })
  }

  getSessionPromise({user}){
    return new Promise((resolve, reject)=>{
      user.getSession((err, session)=> {
        if (session && session.isValid()) {
          resolve({session: session});
        } else {
          reject(err);
        }
      });
    })
  }

  fetchHistory({session}){
    return new Promise((resolve, reject)=>{
      request
        .get({
          url: `${_config.api.invokeUrl}/fetch-history`,
          json: true,
          headers: {
            Authorization: session.getIdToken().getJwtToken()
          }
        }, (error, response, body)=>{
          if(error){
            reject(error);
          }
          if(body){
            this.setState({entryHistory: body}, ()=>{
              resolve({entryHistory: body});
            });
          }
        })
    })
  }

  logout(){
    this.state.user.signOut();
    this.setState({user: null, entryHistory: {}})
  }

  render(){

    const routeSwitch = this.state.user ?
      (
        <Switch>
          <Route
            exact path="/"
            render={(props)=>(
              <Today
                {...props}
                todayLuxon={this.state.todayLuxon}
                user={this.state.user}
                setUser={this.setUser}
                entryHistory={this.state.entryHistory}
                ready={this.state.ready}
                getSessionPromise={this.getSessionPromise}
                updateEntryHistory={this.updateEntryHistory}
              />
            )}/>
          <Route
            exact path="/date/:date"
            render={(props)=>(
              <Past
                {...props}
                todayLuxon={this.state.todayLuxon}
                currentLuxon={DateTime.fromISO(props.match.params.date)}
                user={this.state.user}
                setUser={this.setUser}
                entryHistory={this.state.entryHistory}
                ready={this.state.ready}
                getSessionPromise={this.getSessionPromise}
                updateEntryHistory={this.updateEntryHistory}
              />
            )}/>
          <Route
            exact path="/calendar"
            render={(props)=>(
              <Calendar
                {...props}
                todayLuxon={this.state.todayLuxon}
                user={this.state.user}
                setUser={this.setUser}
                entryHistory={this.state.entryHistory}
                ready={this.state.ready}
                getSessionPromise={this.getSessionPromise}
              />
            )}/>
          <Route
            exact path="/settings"
            render={(props)=>(
              <Settings
                {...props}
                _settings={this.state._settings}
                settings={this.state.settings}
                user={this.state.user}
                updateSettings={this.updateSettings}
                setUser={this.setUser}
                ready={this.state.ready}
                saveSettings={this.saveSettings}
              />
            )}/>
          <Route
            exact path="/logout"
            render={()=>{
              this.logout();
              return (<Redirect to={'/'}/>)
            }}/>
        </Switch>
      ) : (
        <Switch>
          <Route
            exact path={'/register'}
            render={(props)=>(
              <Register
                {...props}
                setUser={this.setUser}
                userPool={this.state.userPool}
              />)}
          />
          <Route
            render={(props)=>(
              <Login
                {...props}
                setUser={this.setUser}
                userPool={this.state.userPool}
              />)}
          />
        </Switch>
      );

    return (
      <I18nProvider>
        <Router history={browserHistory}>
          {routeSwitch}
        </Router>
      </I18nProvider>
    )
  }
}