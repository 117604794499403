import React from 'react';

export const Camera = ()=> {
  return (<svg
      height="2em"
      width="2em"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M28,6h-4l-4-4h-8.001L8,6H4c0,0-4,0-4,4v12c0,4,4,4,4,4s5.662,0,11.518,0
			c1.614,2.411,4.361,3.999,7.482,4c3.875-0.002,7.167-2.454,8.436-5.889C31.995,23.076,32,22,32,22s0-8,0-12S28,6,28,6z
			 M14.033,21.66C11.686,20.848,10,18.626,10,16c0-3.312,2.684-6,6-6c1.914,0,3.607,0.908,4.706,2.306
			C16.848,13.321,14,16.822,14,21C14,21.223,14.018,21.441,14.033,21.66z M23,27.883c-3.801-0.009-6.876-3.084-6.885-6.883
			c0.009-3.801,3.084-6.876,6.885-6.885c3.799,0.009,6.874,3.084,6.883,6.885C29.874,24.799,26.799,27.874,23,27.883z"/>
      <polygon points="24.002,16 22,16 22,20 18,20 18,22 22,22 22,26 24.002,26 24.002,22 28,22 28,20
			24.002,20 		"/>
    </svg>
  )
}