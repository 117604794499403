import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {DateTime} from 'luxon';
import {Camera} from "../../Icons/camera";
import { Trans } from '@lingui/macro';
import request from "request";
// import * as jsPDF from 'jspdf/jspdf.debug.js';
import {_config} from "../../../config";

var jsPDF = require('jspdf');

export class Footer  extends Component{
  static propTypes = {
    user: PropTypes.object
  }

  state = {
    showMenu: false
  };

  constructor(){
    super();

    this.uploadPhoto = this.uploadPhoto.bind(this);
  }

  uploadPhoto(event){
    event.persist();

    const tempPhotos = [];

    for(let i = 0; i < event.currentTarget.files.length; i++){
      const val = event.currentTarget.files[i];
      const reader = new FileReader();
      reader.readAsBinaryString(val);

      const index = i;
      tempPhotos.push({type: 'loading', index: index});

      reader.onload = ()=> {
        const formData = {
          date: this.props.currentLuxon.toFormat('yyyyLLdd'),
          fileName: val.name,
          file: btoa(reader.result)
        };

        this.props.user.getSession((err, session)=> {
          request
            .post({
              url: `${_config.api.invokeUrl}/upload-photo`,
              json: true,
              body: formData,
              headers: {
                Authorization: session.getIdToken().getJwtToken()
              }
            }, (err, result, body)=>{
              if(err){
                console.log("ERROR", err);
              }
              if(body){
                const photos = this.props.photos.filter(val=>val.index !== index).concat([body]);
                this.props.handleChange({target: {name: 'photos', value: photos}});
              }
              console.log("BODY?", body)
            })
        });

      };
      reader.onerror = ()=> {
        console.log('there are some problems');
      };
    };
    const photos = this.props.photos.concat(tempPhotos);
    this.props.handleChange({target: {name: 'photos', value: photos}});
  }


  render(){
    let contents = (<Trans>Save Changes</Trans>);

    if(this.props.isSaved){
      contents = (<div><Trans>Saved</Trans> {this.props.lastSave ? `(${DateTime.fromMillis(this.props.lastSave).toLocaleString(DateTime.TIME_WITH_SECONDS)})` : ''}</div>);
    }

    return (
      <footer id={'footer'} className={this.props.ready ? '' : 'invisible'}>
        <div className={'footerBox backgroundColor'}>
          <div
            style={{flex: 1}}>
            {this.state.showMenu ?
              <div className={'menu'}>
                <div className={'cell'}>
                  <button
                    className={'cellButton accentColor backgroundColor hover'}
                    onClick={()=>{
                      this.setState({showMenu: false});
                      this.props.createPDF();
                    }}
                  >
                    Download as PDF
                  </button>
                </div>
              </div>
              : ''
            }
                <a
                  href={"#"}
                  className={'accentColor backgroundColor options'}
                  style={{textAlign: 'left'}}
                  onClick={()=>this.setState(state=>({showMenu: !state.showMenu}))}
                >
                  Options
                </a>
          </div>
          <div
            style={{width: '50px', textAlign: 'center'}}
          >
            <button
              onClick={()=>document.getElementById('photo').click()}
              className={'accentColor'}
              style={{borderRadius: '50px', border: 'none', background: 'none'}}>
              <Camera/>
            </button>
            <form id={'photoForm'} encType="multipart/form-data" style={{display: 'none'}}>
              <input
                id={`photo`}
                type="file"
                accept="image/*"
                multiple
                onChange={this.uploadPhoto}
              />
            </form>
          </div>

          <button
            style={{flex: 1}}
            className="accentColor backgroundColor save" onClick={this.props.saveChanges}>
            {contents}
          </button>
        </div>
      </footer>)
  }
}
