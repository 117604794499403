import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Prompt} from 'react-router-dom'
import {Header} from "../../Components/Header";
import {MonthBar} from "../../Components/MonthBar";
import {DateTime} from 'luxon';
import {CurrentDate} from "../../Components/CurrentDate";
import {Footer} from "../../Components/Footer";
import request from 'request';
import {_config} from "../../../config";
import {createPDF} from "../../Functions/createPDF";
import {PhotoBar} from "../../Components/PhotoBar";
import {TextAreaHOC} from "../../Components/TextAreaHOC";

export class Today extends Component{
  static propTypes = {
    todayLuxon: PropTypes.object,
    user: PropTypes.object,
    setUser: PropTypes.func,
    getSessionPromise: PropTypes.func,
  };

  constructor(){
    super();

    this.state = {
      timer: null,
      entry: '',
      commentary: '',
      savedCommentary: '',
      photos: [],
      grammarlyInterval: null
    };

    this.handleChange = this.handleChange.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.checkForGrammarlyUpdate = this.checkForGrammarlyUpdate.bind(this);
    this.createPDF = this.createPDF.bind(this);
  }

  componentDidMount(){
    const grammarlyInterval = setInterval(this.checkForGrammarlyUpdate, 2000);
    const savedEntry = this.props.entryHistory[this.props.todayLuxon.toFormat('yyyyLLdd')] ? this.props.entryHistory[this.props.todayLuxon.toFormat('yyyyLLdd')].e : '';

    this.setState({grammarlyInterval, entry: savedEntry});

    this.props.getSessionPromise({user: this.props.user})
      .then(({session})=>{
        return new Promise((resolve, reject)=>{
          request
            .get({
              json: true,
              url: `${_config.api.invokeUrl}/fetchpost?date=${this.props.todayLuxon.toFormat('yyyyLLdd')}`,
              headers: {
                Authorization: session.getIdToken().getJwtToken()
              }
            }, (error, response, body)=> {
              if(error) reject(error);
              if(body){
                this.setState({
                  entry: body.e || '',
                  photos: body.p ? body.p : [],
                  commentary: body.c || '',
                  lastSave: Date.now(),
                }, ()=>{
                  if(this.state.entry === ''){
                    document.getElementById('entryTextarea').focus();
                  }
                  resolve();
                });
              }
            })
        })
      })
      .then(()=>{})
      .catch(err=>{
        console.log(err);
      });
  }

  componentWillUnmount(){
    if(this.state.grammarlyInterval){
      clearInterval(this.state.grammarlyInterval);
      clearTimeout(this.state.timer);
    }
  }

  handleChange(event){
    if(event.target.name === 'entry' || event.target.name === 'commentary'){
      clearTimeout(this.state.timer);
      this.setState({[event.target.name]: event.target.value});
      this.setState({timer: setTimeout(()=>{this.saveChanges()}, 5000)})
    }
    if(event.target.name === 'photos'){
      this.setState({[event.target.name]: event.target.value});
    }
  }

  checkForGrammarlyUpdate(){
    const commentary = document.getElementById('commentaryTextarea');
    if(commentary && this.state.commentary !== commentary.value){
      this.setState({commentary: commentary.value});
    }
  }

  createPDF(){
    createPDF({
      fileName: this.props.todayLuxon.toFormat('yyyy-LL-dd'),
      cover: {
        title: 'PermaJournal',
        subtitle: this.props.todayLuxon.toLocaleString(DateTime.DATE_FULL)
      },
      days: [
        {
          date: this.props.todayLuxon.toLocaleString(DateTime.DATE_HUGE),
          entry: this.state.entry,
          commentary: this.state.commentary
        }
      ]
    })
  }

  saveChanges(){
    const entry = document.getElementById('entryTextarea').value;
    const savedEntry = this.props.entryHistory[this.props.todayLuxon.toFormat('yyyyLLdd')] ? this.props.entryHistory[this.props.todayLuxon.toFormat('yyyyLLdd')].e : null;

    this.props.getSessionPromise({user: this.props.user})
      .then(({session})=>{
        const formData = {
          date: +this.props.todayLuxon.toFormat('yyyyLLdd'),
          entry: entry
        };

        request
          .post({
            url: `${_config.api.invokeUrl}/update-entry`,
            json: true,
            body: formData,
            headers: {
              Authorization: session.getIdToken().getJwtToken()
            }
          })
          .on('response', (response) => {
            this.setState({
              lastSave: Date.now()
            });
            this.props.updateEntryHistory({date: this.props.todayLuxon.toFormat('yyyyLLdd'), entry: entry});
            console.log("SUCCESS", response)
          })
          .on('error', (error) => {
            console.log("ERROR", error)
          })
      })
      .catch(err=>{console.log(err)});
  }

  focusCursor(){
    document.getElementById('entryTextarea').focus()
  }

  render(){
    const savedEntry = this.props.entryHistory[this.props.todayLuxon.toFormat('yyyyLLdd')] ? this.props.entryHistory[this.props.todayLuxon.toFormat('yyyyLLdd')].e : null;

    return(
      <div>
        <Prompt
          when={(this.state.entry || savedEntry) && this.state.entry !== savedEntry}
          message={(location, action)=>'You have unsaved changes! Are you sure you want to leave?'}
        />
        <div className={'container'} onClick={this.focusCursor}>
          <div className={'backer'}/>
          <Header
            theme={true}
            user={this.props.user}
            setUser={this.props.setUser}
          />
          <MonthBar
            showActive={true}
            entryHistory={this.props.entryHistory}
            todayLuxon={this.props.todayLuxon}
            date={this.props.todayLuxon.toFormat('yyyyMMdd')}
            ready={this.props.ready}
          />
          <CurrentDate
            currentLux={this.props.todayLuxon}
          />

          <div id={'entry'}>
          <TextAreaHOC
            id={'entryTextarea'}
            name={'entry'}
            value={this.state.entry}
            disabled={!this.props.ready}
            handleChange={this.handleChange}
          />
          </div>
          <div style={{height: '2em'}}/>

          <PhotoBar
            user={this.props.user}
            currentLuxon={this.props.todayLuxon}
            handleChange={this.handleChange}
            photos={this.state.photos}
          />
        </div>
        <Footer
          ready={this.props.ready}
          user={this.props.user}
          isSaved={this.state.entry === savedEntry}
          lastSave={this.state.lastSave}
          photos={this.state.photos}
          currentLuxon={this.props.todayLuxon}
          handleChange={this.handleChange}
          createPDF={this.createPDF}
          saveChanges={this.saveChanges}
        />
      </div>
    )
  }
}
