import React, {Component} from 'react';
import { Trans } from '@lingui/macro';
import {Link} from 'react-router-dom';
import {Header} from "../../Components/Header";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import * as zxcvbn from 'zxcvbn';

export class Register extends Component{
  constructor(){
    super();

    this.state = {
      email: '',
      password: '',
      score: null,
      warning: null,
      suggestions: [],
      error: '',
      message: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.register = this.register.bind(this);
  }

  handleChange(event){
    this.setState({[event.target.name]: event.target.value, error: '', message: ''})

    if(event.target.name === 'password'){
      if(event.target.value){
        const strength = zxcvbn(event.target.value);
        this.setState({
          score: strength.score,
          warning: strength.feedback.warning,
          suggestions: strength.feedback.suggestions || [],
        })
      } else {
        this.setState({score: null, warning: null, suggestions: []});
      }
    }
  }

  register(event){

    function validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    event.preventDefault();

    if(!validateEmail(this.state.email)){
      this.setState({error: "Please check your email adddress"});
    }
    else if(this.state.score < 3){
      this.setState({error: "Sorry, your password looks a little weak. Can you make it longer or more complex?"})
    } else {

      const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute({
        Name: 'email',
        Value: this.state.email
      });

      this.props.userPool.signUp(this.state.email, this.state.password, [attributeEmail], null,
        (err, result) =>{
          if (!err) {
            const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
              Username: this.state.email,
              Pool: this.props.userPool
            });

            const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
              Username: this.state.email,
              Password: this.state.password
            });

            cognitoUser.authenticateUser(authenticationDetails, {
              onSuccess: (result)=>{
                console.log(result);
                this.props.setUser(cognitoUser);
                this.props.history.push('/');
              },
              onFailure: (result)=>{
                console.log(result);
                if(result.message){
                  this.setState({error: result.message, message: ''})
                } else {
                  window.alert("Create User Failed!")
                }
              }
            });

          } else {
            if(err.message){
              this.setState({error: err.message, message: ''});
            } else {
              this.setState({error: "Create User Failed!", message: ''});
            }
          }
        }
      );
    }
  }

  render(){
    return (
      <div className="container">
        <Header
          theme={true}
        />
        <div className="loginContainer">
          <h2><Trans>Start Journaling</Trans></h2>
          <form id="signUp" onSubmit={this.register}>
            <div>
              <input
                className={'loginInput'}
                name="email"
                type="email"
                placeholder="Email"
                value={this.state.email}
                onChange={this.handleChange}
                autoFocus/>
            </div>
            <div>
              <input
                className={'loginInput'}
                name="password"
                type="password"
                placeholder="Password"
                value={this.state.password}
                onChange={this.handleChange}
              />
              <div className={'passwordStrengthContainer'}>
                <div
                  className={`passwordFiller filler${this.state.score}`}
                />
              </div>
              <ul className={'passwordHints'}>
                {this.state.warning ? <li>{this.state.warning}</li> : ''}
                {this.state.suggestions.map((val, index)=>(
                  <li key={index}>
                    {val}
                  </li>
                ))}
              </ul>
            </div>
            <div className={'error'}>
              {this.state.error ? this.state.error : ''}
            </div>
            <div className={'message'}>
              {this.state.message ? this.state.message : ''}
            </div>
            <div>
              <input className={'registerButton'} type="submit" value="Sign Up"/>
            </div>
          </form>
          <div style={{marginTop: '50px'}}>
            <Link className="accentColor link" to="/">Log In</Link>
          </div>
        </div>

      </div>
    )
  }
}