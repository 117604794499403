import React from 'react';

export const Unlocked = ()=> {
  return (<svg
      height="1em"
      width="1em"
      viewBox="0 0 475.078 475.078"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M437.537,74.086c-25.029-25.031-55.151-37.544-90.365-37.544c-35.207,0-65.329,12.513-90.359,37.544
		c-25.031,25.028-37.544,55.15-37.544,90.362v54.821H27.408c-7.612,0-14.083,2.663-19.414,7.993C2.664,232.589,0,239.062,0,246.677
		v164.455c0,7.611,2.664,14.089,7.994,19.417c5.33,5.325,11.801,7.987,19.414,7.987h274.084c7.618,0,14.086-2.662,19.418-7.987
		c5.325-5.331,7.994-11.806,7.994-19.417V246.677c0-7.611-2.669-14.084-7.994-19.414c-5.332-5.33-11.8-7.993-19.418-7.993h-27.404
		v-54.821c0-20.175,7.132-37.402,21.409-51.675c14.277-14.275,31.505-21.411,51.675-21.411c20.177,0,37.404,7.135,51.682,21.411
		c14.27,14.272,21.408,31.5,21.408,51.675v73.089c0,4.95,1.808,9.235,5.428,12.848c3.614,3.614,7.898,5.421,12.847,5.421h18.274
		c4.949,0,9.227-1.807,12.848-5.421c3.613-3.613,5.42-7.898,5.42-12.848v-73.089C475.078,129.236,462.563,99.118,437.537,74.086z"/>
    </svg>
  )
}