import React, {Component} from 'react';
import { Trans } from '@lingui/macro';
import {Link} from 'react-router-dom';
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import {Header} from "../../Components/Header";

export class Login extends Component{
  constructor(){
    super();

    this.state = {
      email: '',
      password: '',
      error: '',
      message: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.login = this.login.bind(this);
  }

  handleChange(event){
    this.setState({[event.target.name]: event.target.value, error: '', message: ''})
  }

  login(event){
    event.preventDefault();
    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
      Username: this.state.email,
      Password: this.state.password
    });

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: this.state.email,
      Pool: this.props.userPool
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result)=>{
        console.log(result);
        this.props.setUser(cognitoUser);
      },
      onFailure: (result)=>{
        console.log(result);
        if(result.message){
          this.setState({error: result.message, message: ''});
        } else {
          this.setState({error: "Login Failed!", message: ''});
        }
      }
    });
  }

  render(){
    return (
      <div className="container">
        <Header
          theme={true}
        />

      <div className="loginContainer">
        <h2><Trans>Log In</Trans></h2>
        <form id="login" onSubmit={this.login}>
          <div>
            <input
              className={'loginInput'}
              name="email"
              type="email"
              placeholder="Email"
              value={this.state.email}
              onChange={this.handleChange}
              autoFocus/>
          </div>
          <div>
            <input
              className={'loginInput'}
              name="password"
              type="password"
              placeholder="Password"
              value={this.state.password}
              onChange={this.handleChange}
            />
          </div>
          <div className={'error'}>
            {this.state.error ? this.state.error : ''}
          </div>
          <div className={'message'}>
            {this.state.message ? this.state.message : ''}
          </div>
          <div>
            <input className={'registerButton'} type="submit" value="Log In"/>
          </div>
        </form>
        <div style={{marginTop: '50px'}}>
          <Link className="accentColor link" to="/register">
            Create Account
          </Link>
        </div>
      </div>
    </div>
  )
  }
}