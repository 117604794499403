export function setTheme(){
  const theme = localStorage.getItem('theme') || 'light';

  document.body.className = theme;
}

export function changeTheme(){
  const newTheme = localStorage.getItem('theme') === 'dark' ? 'light' : 'dark';
  localStorage.setItem('theme', newTheme);

  //Check if Grammarly is there -- if so, we'll need to reload the page to keep colors working.
  if(document.getElementsByTagName('grammarly-ghost').length){
    window.location.reload();
  } else {
    setTheme();
  }

}